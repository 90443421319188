export const SWEDISH = "S";
export const EUROPEAN = "E";

export const getText = (
  currentDate: Date,
  openDate: Date,
  closeDate: Date,
  type: typeof SWEDISH | typeof EUROPEAN
) => {
  const now = new Date(currentDate.setHours(0, 0, 0, 0));
  const isBeforeOpenDate = now.getTime() <= openDate.getTime();

  const subject =
    type === SWEDISH
      ? "Svenska transferfönstret"
      : "Europeiska transferfönstret";

  if (isBeforeOpenDate) {
    const diff = openDate.getTime() - now.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days > 0)
      return `${subject} öppnar om ${days} dag${days > 1 ? "ar" : ""}`;
    if (days === 0) return `${subject} öppnar idag`;
  }

  const diff = closeDate.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (days > 0)
    return `${subject} stänger om ${days} dag${days > 1 ? "ar" : ""}`;
  if (days === 0) return `${subject} stänger idag`;
  return `${subject} stängde för ${-days} dag${days < -1 ? "ar" : ""} sedan`;
};
